<!--
 * @Author: DY
 * @Date: 2021-01-04 12:19:30
 * @LastEditTime: 2021-06-08 15:33:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\accountingManagement\ProrataRates.vue
-->
<template>
    <div class="ProrataRates">
        <div class="ProrataRatesContent">
            <div>
                <h4>调整后砼单价：</h4>
                <el-input v-model="concrete_price" type="number" placeholder="请输入调整后单价"></el-input>
            </div>
            <div>
                <h4>调整后砂浆单价：</h4>
                <el-input v-model="mortar_price" type="number" placeholder="请输入调整后单价"></el-input>
            </div>
        </div>

        <footer>
            <button class="save" @click="save()">
                确定
            </button>
            <button class="Close" @click="Close">
                关闭
            </button>
        </footer>
    </div>
</template>

<script>
export default {
    // 发货单核算-调整单价
    name: 'prorata-rates',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            concrete_price: '',
            mortar_price: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.extr && this.extr.row) {
            this.concrete_price = this.extr.row.adjusted_concrete_price;
            this.mortar_price = this.extr.row.adjusted_mortar_price;
        }
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 确定
        save() {
            this.$axios
                .put('/interfaceApi/sale/accounts/batch_adjusted_price_by_task/'
                + `${this.extr.row.daily_task_number}?adjusted_concrete_price=${this.concrete_price}&adjusted_mortar_price=${this.mortar_price}`)
                .then(() => {
                    this.$message({
                        message: '已调整!',
                        type: 'success',
                    });
                    this.extr.table.updateTable();
                    this.$parent.hide();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        Close() {
            this.$parent.hide();
        },
    },
};
</script>
<style lang="stylus" scoped>
.ProrataRates
    height 100%
    padding 0.14rem
    .ProrataRatesContent
        height calc(100% - 0.5rem)
        padding-top 0.3rem
        >div
            display flex
            margin-bottom 0.2rem
            h4
                white-space nowrap
                font-size 0.16rem
                line-height 0.36rem
                text-align right
                width 1.3rem
            .el-input
                width calc(100% - 1.3rem)
    footer
        height 0.4rem
        text-align center
        button
            width 1.5rem
            height 0.4rem
            font 0.18rem '微软雅黑'
            margin 0 0.15rem
            border-radius 2px
            border none
            cursor pointer
            &.save
                color #fff
                background #1577D2
            &.Close
                color #1577D2
                background #fff
                border 1px solid #1577D2
</style>